type Props = Readonly<{
  className?: string;
}>;

export function Rain({ className }: Props): JSX.Element {
  return (
    <svg
      width={106}
      height={48}
      viewBox="0 0 106 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-[#F73196]"
        d="M54.3575 29.4468C54.3575 35.2749 49.6324 40 43.8043 40H30.1872C24.3592 40 19.634 35.2749 19.634 29.4468C19.634 23.6187 24.3592 18.8936 30.1872 18.8936H43.4639V26.383H30.1872C28.4987 26.383 27.1234 27.7583 27.1234 29.4468C27.1234 31.1353 28.4987 32.5106 30.1872 32.5106H43.8043C45.4928 32.5106 46.8681 31.1353 46.8681 29.4468V18.5532C46.8681 16.8647 45.4928 15.4894 43.8043 15.4894H14.3779C10.623 15.4894 7.56941 18.543 7.56941 22.2979V40H0.0800171V22.2979C0.0800171 14.4 6.48002 8 14.3779 8H43.8043C49.6324 8 54.3575 12.7251 54.3575 18.5532V29.4468Z M65.5915 8H58.1021V40H65.5915V8Z M97.9318 22.2979C97.9318 18.543 94.8779 15.4894 91.1235 15.4894H76.8258V40H69.3362V11.7447C69.3362 9.67489 71.0108 8 73.0811 8H91.1235C99.0212 8 105.421 14.4 105.421 22.2979V40H97.9318V22.2979Z"
      />
    </svg>
  );
}
