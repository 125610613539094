type Props = Readonly<{
  className?: string;
}>;

export function GloDollar({ className }: Props): JSX.Element {
  return (
    <svg
      width={245}
      height={48}
      viewBox="0 0 245 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#24e5df"
        d="M48,24C48,10.7,37.3,0,24,0S0,10.7,0,24s10.7,24,24,24,24-10.7,24-24Z"
      />
      <path
        fill="#133d38"
        d="M24,4.1C13,4.1,4.1,13.1,4.1,24.1s8.9,20,20,20,20-9,20-20S35.1,4.1,24,4.1ZM30.8,30.9c-6.8,6.8-15.3,9.3-19,5.5-3.7-3.7-1.3-12.3,5.5-19.1,6.8-6.8,15.3-9.3,19-5.5,3.7,3.7,1.3,12.3-5.5,19.1Z"
      />
      <path
        className="fill-[#133d38] dark:fill-white"
        d="M79.8,27.7h3.8c-.9,3.2-2.7,5-6.4,5s-7.8-2.7-7.8-8.8,2.3-9,8-9,6.1,1.4,7.1,4.4h5.8c-.9-5-5.4-9.3-13.5-9.3s-13.9,7.7-13.9,14.3,6.4,13.9,13.1,13.9,7.8-3.7,8.6-5.8h0c0-.2.2-.4.5-.3.2,0,.2.3,0,.7-.6,1.4-.6,2.3-.6,3.2v1.9h5.8v-14.7h-10.6v4.6ZM94.2,10v23c0,2.6,1.9,4.8,4.6,4.8h3.3v-4.9c-1.5.3-2.5.2-2.5-1.5l.2-21.3h-5.6ZM159.8,24.3c0,7.7-4.3,13.8-14.9,13.8h-10.8V10h10.8c10.8,0,14.9,6.6,14.9,14.3ZM154.2,24c0-4.9-2.8-9.1-8.7-9.1h-5.4v17.8h5.4c5.9,0,8.7-3.8,8.7-8.7ZM186.4,10v23c0,2.6,1.9,4.8,4.6,4.8h3.3v-4.9c-1.5.3-2.5.2-2.5-1.5l.2-21.3h-5.6ZM196.7,10h5.6l-.2,21.3c0,1.7,1.1,1.9,2.5,1.5v4.9h-3.3c-2.6,0-4.5-2.1-4.5-4.8V10ZM103.6,26.7c0-6.2,4.1-11.3,11-11.3s11,5.1,11,11.3-4.1,11.3-11,11.3-11-5.1-11-11.3ZM108.9,26.7c0,3.6,2,6.5,5.7,6.5s5.7-2.9,5.7-6.5-2-6.5-5.7-6.5-5.7,2.9-5.7,6.5ZM173.1,38.4c6.8,0,10.9-5,10.9-11.2s-4.1-11.2-10.9-11.2-10.9,5-10.9,11.2,4.1,11.2,10.9,11.2ZM173.1,20.7c3.6,0,5.6,2.9,5.6,6.4s-2,6.4-5.6,6.4-5.6-2.9-5.6-6.4,2-6.4,5.6-6.4ZM216.8,25.2c2.9,0,5.2-.1,5.2-1.8,0-1.5-.6-3.1-4.4-3.1s-4.7,1.5-4.9,3.3h-5.2c.7-5.5,5.4-7.6,10.3-7.6s9.5,1.6,9.5,10.1v12h-5.3v-1.5c0-1,.2-1.7.6-2.4.2-.4.3-.8,0-.9-.4-.2-.6.2-.7.5-1.2,3-3.6,4.7-7.5,4.7s-7.6-2.6-7.6-6.3,3.5-6.6,7.9-6.8c.7,0,1.4,0,2.1,0ZM215.6,28.7c-2.3.2-3.5,1.1-3.5,3s1.7,3,4.2,2.6c2.8-.4,5.7-2.2,5.7-7.6-1.2,1.4-3.2,1.6-6.3,1.9h0ZM243.9,16c-5.3,0-6.5,1.6-7.8,4.6,0,.1-.2.5-.5.4-.5,0-.4-.6-.2-.9.4-.7.6-1.6.6-2.5v-1.2h-5.3v21.8h5.3v-9.4c0-5.5,1.8-7.7,7.9-7.3v-5.4Z"
      />
    </svg>
  );
}
