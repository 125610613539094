type Props = Readonly<{
  className?: string;
}>;

export function Etherfuse({ className }: Props): JSX.Element {
  return (
    <svg
      width={177}
      height={48}
      viewBox="0 0 177 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-black dark:fill-[#DFFF32]"
        d="M43.3,26h3.6v3.5h-3.6v9.9h-3.7V8h3.7v18ZM36,22.3h-5.4v11.2c0,1.1.2,1.7.6,2.2.4.4,1.1.7,1.8.7s1.8,0,3.1,0v3.3c-3.2.4-5.5.2-6.9-.8-1.4-1-2.2-2.7-2.2-5.2v-11.3h-4.1v-3.5h4.1v-7.2h3.6v7.2h5.5v3.5ZM21.2,29.2c0-3-1-5.6-2.9-7.7-1.9-2.1-4.3-3.2-7.4-3.2s-5.8,1-7.8,3.1C1,23.5,0,26.1,0,29.2s1.1,5.7,3.1,7.8c2,2,4.7,3.1,8,3.1s7.1-1.6,9.2-4.7l-3.1-1.7c-1.3,2-3.3,3-6,3s-3.6-.5-4.9-1.5c-1.2-1-2.1-2.4-2.5-4.2h17.3c0-.6,0-1.1,0-1.6ZM3.8,27.5c.4-1.8,1.2-3.3,2.4-4.3,1.3-1.1,2.9-1.6,4.8-1.6s3.1.5,4.3,1.5c1.3,1,2,2.5,2.3,4.4H3.8ZM176.1,29.2c0-3-1-5.6-2.9-7.7-1.9-2.1-4.3-3.2-7.4-3.2s-5.8,1-7.8,3.1c-2,2.1-3,4.7-3,7.8s1.1,5.7,3.1,7.8c2,2,4.7,3.1,8,3.1s7.1-1.6,9.2-4.7l-3.1-1.7c-1.3,2-3.3,3-6,3s-3.6-.5-4.9-1.5c-1.2-1-2.1-2.4-2.5-4.2h17.3c0-.6,0-1.1,0-1.6ZM158.7,27.5c.4-1.8,1.2-3.3,2.4-4.3,1.3-1.1,2.9-1.6,4.8-1.6s3.1.5,4.3,1.5c1.3,1,2,2.5,2.3,4.4h-13.9ZM85.5,18.7h3.7v3.6h3.6v3.5h-3.6v13.5h-3.7v-20.7ZM96.3,18.7h-3.6v3.5h3.6v-3.5ZM123.8,36.5c1.7,0,3.3-.5,4.3-1.6,1.8-2,1.8-4,1.8-4.8,0-.2,0-.3,0-.3v-3.8h3.6v13.4h-3.6v-3c-1.4,2.4-3.8,3.5-6.8,3.5s-4.4-.8-5.9-2.3c-1.4-1.5-2.2-3.6-2.2-6.2v-12.6h3.6v12.6c0,1.7.5,3,1.4,3.9.9.9,2.1,1.3,3.8,1.3ZM133.3,18.7h-3.6v3.5h3.6v-3.5ZM140.6,18.7h7.7v3.5h-7.7v-3.5ZM151.9,22.4h-3.6v3.5h3.6v-3.5ZM137,22.4h3.6v3.5h3.6v3.5h-3.6v-3.5h-3.6v-3.5ZM144.2,29.6h7.7l.2,3.5c0,2.2-.7,3.9-2.1,5.1-1.4,1.1-3.3,1.7-5.6,1.7s-3.8-.5-5.2-1.3c-1.4-1-2.5-2.1-3.1-3.5l3.1-1.8c.3,1.1,1,1.8,1.8,2.4.9.6,2,.9,3.3.9s2.2-.2,3-.7c.8-.5,1.1-1.2,1.1-2.7h-4.1v-3.5ZM74.7,28.5h7.2v-6.1h-3.6v-3.6h-7.2c-2.4,0-4.7,1-6.6,2.5-2.2,1.9-3.4,4.7-3.4,7.9s1.1,5.7,3.1,7.8c2,2,4.7,3.1,8,3.1s7.1-1.5,9.2-4.7l-3.1-1.7c-1.3,2-3.3,3-6,3s-3.7-.5-4.9-1.5c-1-.8-1.7-1.8-2.2-2.9h9.5v-3.5ZM74.6,28.5h-10c0-.3,0-.6,0-1,.3-1.1,1.2-2.9,2.2-3.8.9-.8,2.3-1.3,4.1-1.3h7.2v2.6h-3.7v3.5ZM46.9,18.7h7.5v3.5h-7.5v-3.5ZM58,22.4h-3.6v17.1h3.6v-17.1ZM106.4,18.7v-7.1h-3.7v7.1h-3.3v3.6h3.3v17.1h3.7v-17.1h5.4v-3.6h-5.4ZM106.4,8h5.5v3.5h-5.5v-3.5Z"
      />
    </svg>
  );
}
