type Props = Readonly<{
  className?: string;
}>;

export function CantonFull({ className }: Props): JSX.Element {
  return (
    <svg
      width={183}
      height={48}
      viewBox="0 0 183 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1,15.6l-4.6,2.2c-3.7-6.3-15-6.7-20.3-2.6-5.3,4.1-5.2,14.2.7,18,5.6,3.5,16,3.1,19.7-3.1l4.6,2.3c-3.7,6.1-11.5,7.9-18.2,7.5-11.5-.8-18.9-10.2-15-21.5C5.2,5.9,25.6,4.9,33.3,14.2c.2.3,1,1.2.8,1.4h0ZM65.4,39.9h-5.1l-.9-4c-4.1,4.2-10.5,4.9-15.9,3.1-8.5-2.8-8-12.3.5-14.8,5.3-1.5,11.6-.8,15.4,3.3v-3.4c0-.7-1.1-2.7-1.6-3.3-3.3-3.6-12.6-2.6-16.3,0l-1.7-3c-.1-.4.3-.6.6-.8,4.5-2.8,13.9-3.2,18.6-.9,9.1,4.5,3.6,16.1,6.4,23.7h0ZM49.6,27c-3.2.1-8.4,1.5-7,5.8,1.4,4.3,9.4,3.9,12.7,2.7,1.3-.5,4.5-2.5,4.3-4,0-.5-1.4-1.8-1.8-2.2-2.2-1.7-5.5-2.3-8.2-2.2h0ZM134.7,14.6c9.6-.8,17.3,4,16.4,14.4-1.3,14.6-27.9,14.7-29.1-.2-.7-8.5,4.5-13.6,12.7-14.2h0ZM135.3,18.8c-5.6.5-8.9,4-8.3,9.7.9,9.4,17.5,9.8,19.2.7,1.4-7.2-4.3-11-10.9-10.4ZM97.3,39.9h-5.1v-15.7c0-1.6-1.7-3.9-3.2-4.6-4.7-2.2-10.9.3-13.2,4.8v15.6h-5.1V15.5h5.1v4.4l1.1-1.4c3-3.2,7.8-4.4,12.1-3.7,11.7,2,7.5,16.7,8.3,25.1ZM182.8,39.9h-4.9v-14.8c0-7.3-9.5-7.6-13.9-3.9-.5.4-2.6,2.8-2.6,3.4v15.4h-4.9V15.5h4.9v4.4c3.3-4.9,10.8-6.6,16.1-4.2,2.7,1.3,5.4,5.2,5.4,8.3v15.9ZM109.8,10v5.5h10.2v4h-10.2v13.2c0,0,.4,1,.5,1.1,1.7,2.7,6.2,2,8.5.4l1.3,3.5c-5.4,3.7-14.7,3.1-15.4-4.8-.3-4.3.3-9,0-13.4h-4.6v-4h4.6v-5.5h5.1Z"
        className="fill-[#272727] dark:fill-[#F3FF97]"
      />
    </svg>
  );
}
