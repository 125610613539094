type Props = Readonly<{
  className?: string;
}>;

export function Caldera({ className }: Props): JSX.Element {
  return (
    <svg
      viewBox="0 0 208 48"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.31,12.08h-18.2L0,40h54.43l-18.12-27.92Z"
        className="fill-black dark:fill-[#c9c9c9]"
      />
      <path
        d="M36.31,12.08h-18.2l36.31,27.92-18.12-27.92Z"
        className="fill-[#4d4e52] dark:fill-white"
      />
      <path
        d="M76.11,22.14c-.77-1.58-2.58-4.7-7.05-4.7-6.06,0-8.23,5.07-8.23,8.86,0,4.34,2.76,8.92,8.14,8.92,3.53,0,5.93-2.03,7.1-4.61h5.24c-1.9,5.56-6.78,8.92-12.35,8.92-8.23,0-12.84-6.74-12.84-13.21s4.98-13.16,12.89-13.16c7.1,0,11.35,5.29,12.3,9l-5.2-.02ZM103.95,38.87v-3.89h-.09c-1.81,3.08-5.11,4.53-8.5,4.53-8.46,0-13.3-6.47-13.3-13.07,0-6.11,4.3-13.3,13.3-13.3,3.48,0,6.69,1.45,8.5,4.21h.09v-3.53h4.69v25.05l-4.69-.02ZM95.36,17.43c-5.79,0-8.59,5.02-8.59,8.96,0,4.52,3.57,8.82,8.55,8.82s8.73-4.02,8.73-8.82c0-5.33-3.84-8.96-8.68-8.96ZM115.8,38.87h-4.7V8h4.7v30.87ZM138.91,38.87v-3.57h-.09c-1.99,2.76-5.2,4.22-8.87,4.22-8.32,0-13.02-6.47-13.02-13.07,0-6.11,4.16-13.3,13.02-13.3,3.48,0,6.69,1.45,8.5,3.75h.09v-8.91h4.7v30.87h-4.34ZM130.23,17.43c-5.79,0-8.59,5.02-8.59,8.96,0,4.52,3.57,8.82,8.55,8.82s8.73-4.02,8.73-8.82c0-5.33-3.85-8.96-8.68-8.96ZM149.13,28.38c1.18,4.84,5.07,6.83,8.23,6.83,1.46.02,2.9-.36,4.16-1.1,1.26-.74,2.29-1.81,2.99-3.09h4.7c-2.4,6.47-8.14,8.5-12.03,8.5-7.1,0-12.75-6.11-12.75-13.21,0-7.51,5.79-13.16,12.84-13.16s12.71,5.52,12.75,12.84c0,.81-.09,2.07-.13,2.4h-20.75ZM165.32,24.76c-.23-3.66-3.57-7.33-8.09-7.33s-7.87,3.66-8.09,7.33h16.18ZM171.11,38.87V13.82h4.39v2.26h.09c1.99-2.8,5.2-2.89,6.38-2.94v4.84c-1.18.07-2.33.38-3.39.9-1.49.82-2.76,2.71-2.76,6.29v13.7h-4.7ZM202.54,38.87v-3.89h-.09c-1.81,3.08-5.11,4.52-8.5,4.52-8.46,0-13.3-6.47-13.3-13.07,0-6.11,4.3-13.3,13.3-13.3,3.48,0,6.69,1.45,8.5,4.21h.09v-3.53h4.7v25.05h-4.7ZM193.95,17.43c-5.79,0-8.59,5.02-8.59,8.96,0,4.52,3.57,8.82,8.55,8.82s8.73-4.02,8.73-8.82c0-5.33-3.85-8.96-8.68-8.96Z"
        className="fill-black dark:fill-white"
      />
    </svg>
  );
}
