import { useCallback, useEffect } from "react";

const BADGE_ID = "44637a5062367b09";
const ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`;
const ALCHEMY_ANALYTICS_URL = "https://analytics.alchemyapi.io/analytics";

const logAnalytics = (endpoint: string) => {
  fetch(`${ALCHEMY_ANALYTICS_URL}/${endpoint}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ badge_id: BADGE_ID }),
  });
};

const isBadgeInViewport = (bounding: DOMRect): boolean => {
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export function Alchemy(): JSX.Element {
  const logBadgeClick = useCallback(() => {
    logAnalytics("badge-click");
    window.open(ALCHEMY_URL, "_blank")?.focus();
  }, []);

  useEffect(() => {
    const logBadgeView = () => logAnalytics("badge-view");

    const intervalId = setInterval(() => {
      const badge = document.getElementById("badge-button");
      if (badge && isBadgeInViewport(badge.getBoundingClientRect())) {
        logBadgeView();
        clearInterval(intervalId);
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <button
        onClick={logBadgeClick}
        id="badge-button"
        className="dark:hidden"
        aria-label="Alchemy Supercharged"
      >
        <img
          src="https://static.alchemyapi.io/images/marketing/rollups-badge-light-2.png"
          alt="Alchemy Certified Infrastructure Partner logo"
          className="h-12 lg:h-16 w-auto object-contain"
        />
      </button>
      <button
        onClick={logBadgeClick}
        id="badge-button-dark"
        className="hidden dark:block"
        aria-label="Alchemy Supercharged"
      >
        <img
          src="https://static.alchemyapi.io/images/marketing/rollups-badge-dark-2.png"
          alt="Alchemy Certified Infrastructure Partner logo"
          className="h-12 lg:h-16 w-auto object-contain"
        />
      </button>
    </>
  );
}

export default Alchemy;
